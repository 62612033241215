<template>
    <div>
        <!--------------------面包屑导航-------------------->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>运营</el-breadcrumb-item>
            <el-breadcrumb-item>订单管理</el-breadcrumb-item>
        </el-breadcrumb>
        <!----------------------卡片区域---------------------->
        <el-card>
            <div class="table-box">
                <div class="search-box">
                    <span class="title" style="margin-left: 0;">订单状态：</span>
                    <el-select v-model="status" size="small" @change="searchOrderList()" placeholder="全部">
                        <el-option label="未结算" :value="0"></el-option>
                        <el-option label="已结算" :value="1"></el-option>
                        <el-option label="结算中" :value="2"></el-option>
                        <el-option label="结算失败" :value="3"></el-option>
                    </el-select>
                    <span class="title">昵称：</span>
                    <el-input size="small" placeholder="请输入达人昵称" v-model="nickname" @keyup.enter.native="searchOrderList()">
                        <i slot="suffix" class="el-icon-search search-bottom" @click="searchOrderList()"/>
                    </el-input>
                </div>
                <el-table :data="orderList" border v-loading="orderLoading" element-loading-text="加载中,请稍候" :header-cell-style="{background:'#FAFAFA'}"><!--stripe带斑马纹的表格 border边框-->
                    <el-table-column type="index" label="序号" width="60px">
                        <template slot-scope="scope">
                            <span>{{ scope.$index + 1 + orderQuery.number * (orderQuery.page - 1) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="nickname" label="昵称"></el-table-column>
                    <el-table-column prop="id" label="订单编号" width="190"></el-table-column>
                    <el-table-column prop="a" label="预约时间" width="192">
                        <template slot-scope="scope">
                            <span>{{ scope.row.start_time + ' - ' + scope.row.end_time }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="hours" label="预约时长">
                        <template slot-scope="scope">
                            <span>{{ scope.row.hours + '小时' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="fullname" label="预约商家"></el-table-column>
                    <el-table-column prop="amount" label="订单金额(元)" min-width="102">
                        <template slot-scope="scope">
                            <span>{{(Number(scope.row.amount) / 100).toFixed(2)}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="pay_amount" label="实付金额(元)" min-width="102">
                        <template slot-scope="scope">
                            <span>{{(Number(scope.row.pay_amount) / 100).toFixed(2)}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="discount_amount" label="优惠抵扣(元)"  min-width="102">
                        <template slot-scope="scope">
                            <span>{{(Number(scope.row.discount_amount) / 100).toFixed(2)}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="" label="操作" width="80">
                        <template slot-scope="scope" >
                            <el-button type="text" size="small" @click="showCheck(scope.row, 0)" v-if="scope.row.settled == 0">确认</el-button>
                            <el-button type="text" size="small" @click="showCheck(scope.row, 1)"  v-if="scope.row.settled == 1 || scope.row.settled == 2">查看</el-button>
                            <el-button type="text" size="small"  @click="showCheck(scope.row, 0)" v-if="scope.row.settled == 3">重新结算</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <!--------------------分页组件--------------------->
                <el-pagination @size-change="handleSizeChange1" @current-change="handleCurrentChange1"
                               :current-page="orderQuery.page" :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size="orderQuery.number"
                               layout="total, sizes, prev, pager, next, jumper" :total="orderTotal">
                </el-pagination>
            </div>

            <!--添加对话框-->
            <el-dialog :title="'分成明细-'+currentObj.order_id" :visible.sync="checkDialog" width="592px"  append-to-body  @close="closeCheckDialog">
                <div class="dialog-content">
                    <el-form label-width="150px">
                        <el-form-item label="手续费(元)：" prop="">
                            {{ (Number(currentObj.fee_amount) / 100).toFixed(2) }}
                        </el-form-item>
                        <el-form-item label="平台分成(元)：" prop="">
                            {{ (Number(currentObj.platform_amount) / 100).toFixed(2) }}
                        </el-form-item>
                        <el-form-item label="达人分成(元)：" prop="">
                            {{ (Number(currentObj.assistant_amount) / 100).toFixed(2) }}
                        </el-form-item>
<!--                        <el-form-item label="商家分成(元)：" prop="">-->
<!--                            {{ (Number(currentObj.arena_amount) / 100).toFixed(2) }}-->
<!--                        </el-form-item>-->
<!--                        <el-form-item label="服务商家分成(元)：" prop="">-->
<!--                            {{ (Number(currentObj.off_arena_amount) / 100).toFixed(2) }}-->
<!--                        </el-form-item>-->
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer" v-if="dialogType == 0" >
                <el-button size="small" @click="checkDialog = false">取 消</el-button>
                <el-button size="small" type="primary" @click="submitCheckForm()" :loading="btnLoading">结 算</el-button>
            </span>
            </el-dialog>
        </el-card>
    </div>
</template>

<script>
export default {
    name: "list",

    data() {
        return {
            status: 0,//订单状态
            orderList: [],//列表
            orderTotal: 0,//总条数
            orderQuery: {
                page: 1,//页码
                number: 10,//数量
            },
            orderLoading: false,//表格加载loading
            checkDialog: false,//确认分成弹窗
            btnLoading: false,//按钮状态
            currentObj: {//分成明细对象
                order_id: '',
                fee_amount: 0,
                platform_amount: 0,
                assistant_amount: 0,
                arena_amount: 0,
                off_arena_amount: 0,
            },
            nickname: '',//搜索条件昵称
            dialogType: 0,//弹窗类型0未结算 1已结算
            loading: false,//表格加载loading
        }
    },
    mounted () {
        this.getOrderList();//查列表
    },
    methods: {
        //从第一页开始重新加载列表
        searchOrderList() {
            this.orderQuery.page = 1
            this.orderQuery.number = 10
            this.getOrderList();
        },
        //查列表
        async getOrderList() {
            this.orderLoading = true;
            let data = {
                //status: this.status,
                page: this.orderQuery.page,
                number: this.orderQuery.number,
                settled: this.status,
                nickname: this.nickname
            }
            let { data: res } = await this.$http.get('/admin/api/order/finished/list', { params: data})
            this.orderLoading = false;
            if (res.code == 200) {
                if(res.data.result) {
                    this.orderList = res.data.result
                    this.orderTotal = res.data.total
                }
            } else {
                this.$message.error(res.message)
            }
        },
        // 监听每页显示多少条的改变
        handleSizeChange1 (newSize) {
            this.orderQuery.page = 1
            this.orderQuery.number = newSize
            this.getOrderList()
        },
        // 监听选择第了几页
        handleCurrentChange1 (newPage) {
            this.orderQuery.page = newPage
            this.getOrderList()
        },
        //打开确认分成明细弹窗
        async showCheck(rows, type) {
            this.dialogType = type
            let { data: res } = await this.$http.get('/admin/api/order/finished/branch', { params: {orderId: rows.id}})
            this.loading = false;
            if (res.code == 200) {
                this.currentObj = res.data.result[0]
                this.checkDialog = true
            } else {
                this.$message.error(res.message)
            }
        },
        //关闭确认分成明细弹窗
        closeCheckDialog() {
            this.currentObj = {
                platform_amount: 0,
                assistant_amount: 0,
                off_arena_amount: 0
            }
            this.checkDialog = false
        },
        //提交分成
        async submitCheckForm() {
            this.btnLoading = true;
            let param = {
                orderNo: this.currentObj.order_id,
            }
            let res = await this.$http.post('/admin/api/order/confirm/branch', param)
            if (res.data.code === 200) {
                this.btnLoading = false;
                this.$message.success('操作成功！')
                this.closeCheckDialog();
                await this.getOrderList();
            } else {
                this.btnLoading = false;
                this.$message.error(res.data.message)
            }
        },
    }
}
</script>

<style scoped>

</style>
